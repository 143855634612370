import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '委外合同号', prop: 'outs_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  {
    label: '合同日期',
    prop: 'cont_date',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, widthAuto: false },
  {
    label: '交货期',
    prop: 'deli_date',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: 120,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '源单号', prop: 'pinv_no', itemType: 'input', input: true, sortable: false, widthAuto: false, labelWidth: 120 },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  {
    label: '发票号码',
    prop: 'pinv_actu_nos',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    labelWidth: 120
  },
  {
    label: '客户订单号',
    prop: 'scon_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false
  },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '合同金额', prop: 'cont_total', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '汇率', prop: 'ppay_rmbrate', itemType: 'input', input: false, sortable: false, widthAuto: false, labelWidth: 100 },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, widthAuto: false, labelWidth: 100 },
  {
    label: '公司抬头',
    prop: 'pinv_cptt_name',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    overflowTooltip: true
  },
  {
    label: '外销经办人',
    prop: 'cust_stff_aname',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false
  },
  {
    label: '本币金额',
    prop: 'scon_rmbtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false
  },
  {
    label: '成品名称',
    prop: 'prod_bhsname',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    overflowTooltip: true
  },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    input: false,
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
